.logoCon {
  width: 12.5rem;
  margin-bottom: 0.75rem;
}

.logoSvg {
  width: 80px;
}

.receipt-checkbox {
  display: flex;
  vertical-align: middle;
  line-height: 15px;
}

.receipt-checkbox > img {
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

.receipt-checkbox > span {
  height: 15px;
  vertical-align: middle;
  margin-left: 5px;
}
