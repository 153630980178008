.rates-wrap {
  position: relative;
}

.rates-file-top {
  margin-top: -3.2rem;
  padding: 6px;
  background: #ffffff;
  width: 99px;
  font-weight: bold;
}

.rates-file-top.preview {
  width: 74px;
}

.download-rate-template {
  display: block;
  color: dodgerblue;
  border-bottom: 1px solid dodgerblue;
  width: 300px;
  box-shadow: border-box;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
}

.rates-preview {
  min-height: 6vh;
  max-height: 50vh;
}

.rates-preview .wrap {
  min-height: 6vh;
  max-height: 50vh;
  overflow-y: scroll;
}

.rates-empty {
  text-align: center;
  margin-top: 2vh;
}

button.check-abs-btn {
  align-self: flex-end;
}

.abs-modal {
  position: absolute;
  z-index: 5;
  border: 1px solid #d3d3d3;
  padding: 15px 25px;
  top: 0;
  left: 5%;
  width: 90%;
  margin: 0 auto;
  overflow-y: scroll;
  background-color: #fefefe;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.abs-modal .close {
  float: right;
  margin-top: -12px;
  display: block;
  margin-right: -18px;
  padding: 10px;
  cursor: pointer;
}

p.ab-msg {
  margin: 0 auto;
  width: 30vw;
  text-align: center;
  margin-top: 50px;
  font-weight: 900;
  font-size: 0.98em;
}

.abs-action-buttons {
  margin-top: 20px;
  float: right;
  margin-right: 30px;
}

.action-wrap {
  display: inline-block;
}

.action-wrap:not(:nth-child(1)) {
  margin-left: 12px;
}

.action-wrap .action {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}
